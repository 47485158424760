


function Footer() {
  return (
    <footer style={{   display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  
  <div style={{ flex: 1, textAlign: 'center' }}>
    <span> © 2023 Mariusz-Portfolio - www.mariuszwiacek.com</span>
  </div>
 
</footer>)
}




export default Footer;